import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DateRangePicker from '~/components/DateRangePicker';
import Header from '~/components/Header';

import api, { omniApi } from '~/services/api';
import { shouldRemoveExternalProducts } from '~/util/functions';
import { formatNumberToHour, formatPrice } from '~/util/format';
import Snackbar from '~/util/SnackBar';
import PageLayout from './DesktopLayout';

export default function StoreOverviewOmni() {
    const { initialDate, finalDate, period } = useSelector(
        state => state.status
    );
    const userProfile = useSelector(state => state.user.profile);
    const [storeRanking, setStoreRanking] = useState(undefined);
    const [omniIndicators, setOmniIndicators] = useState(undefined);
    const [salesCard, setSalesCard] = useState(undefined);
    const [totalValue, setTotalValue] = useState(undefined);
    const [salesPercentage, setSalesPercentage] = useState(undefined);
    const [omniPercentage, setOmniPercentage] = useState(undefined);

    const checkIfCanMakeRequests = useCallback(() => {
        return initialDate && finalDate;
    }, [finalDate, initialDate]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            const getStoreRanking = async () => {
                setStoreRanking(currState => ({
                    ...currState,
                    loaded: false,
                }));

                const params = {
                    dateInit: moment(initialDate).format('YYYY-MM-DD'),
                    dateEnd: moment(finalDate).format('YYYY-MM-DD'),
                    brandId: userProfile.marcaFilial,
                    branchCode:
                        userProfile.users_branches[0].codigo_filial
                            .length === 3
                            ? `000${userProfile.users_branches[0].codigo_filial}`
                            : userProfile.users_branches[0]
                                  .codigo_filial,
                    removeExternalProducts: shouldRemoveExternalProducts(
                        userProfile.marcaFilial
                    )
                };

                if ([2, 6].includes(userProfile.marcaFilial)) {
                    delete params.removeExternalProducts;
                    params.farmLatam = 'exclude';
                    params.offExternal = 'exclude';
                }

                const newStoreRanking = await api.get(
                    `/store-manager/ranking-stores`,
                    {
                        params
                    }
                );

                setStoreRanking({
                    placement: `${newStoreRanking?.data?.rankingPosition}`,
                    totalStores: `${newStoreRanking?.data?.totalBranchesNumber}`,
                    loaded: true,
                });
            };

            const getAggregateSales = async () => {
                setSalesCard(currState => ({
                    ...currState,
                    loaded: false,
                }));

                setTotalValue(currState => ({
                    ...currState,
                    loaded: false,
                    value: null,
                }));

                // não remover, condição para mostrar os valores corretos antes da implementação da lógica de vendas externas da off premium.
                const beforeExternalOffPremiumDateLimit = new Date(
                    '2024-09-27'
                );

                const params = {
                    startDate: moment(initialDate).format('YYYY-MM-DD'),
                    endDate: moment(finalDate).format('YYYY-MM-DD'),
                    filialCode:
                        userProfile.users_branches[0].codigo_filial
                            .length === 3
                            ? `000${userProfile.users_branches[0].codigo_filial}`
                            : userProfile.users_branches[0]
                                  .codigo_filial,
                    filialBrandId: userProfile.marcaFilial,
                    removeExternalProducts: shouldRemoveExternalProducts(
                        userProfile.marcaFilial
                    ),
                }

                if ([2, 6].includes(userProfile.marcaFilial)) {
                    delete params.removeExternalProducts;
                    params.farmLatam = 'exclude';
                    params.offExternal = 'exclude';
                }

                if (
                    moment(initialDate).format('YYYY-MM-DD') <=
                    moment(beforeExternalOffPremiumDateLimit).format(
                        'YYYY-MM-DD'
                    )
                ) {
                    delete params.removeExternalProducts;
                    params.offExternal = 'exclude';
                }

                const salesResponse = await api.get(
                    '/store/aggregated-orders',
                    {
                        params
                    }
                );

                setSalesCard(currentSalesCardData => ({
                    ...currentSalesCardData,
                    mainText: formatPrice(
                        `${salesResponse.data.data.total.value +
                            salesResponse.data.data.returns.value}`
                    ),
                    value:
                        salesResponse.data.data.total.value +
                        salesResponse.data.data.returns.value,
                    loaded: true,
                }));
            };

            const getQuota = async () => {
                const quotaResponse = await api.get('/store/quota', {
                    params: {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        filialCode:
                            userProfile.users_branches[0].codigo_filial
                                .length === 3
                                ? `000${userProfile.users_branches[0].codigo_filial}`
                                : userProfile.users_branches[0].codigo_filial,
                    },
                });

                const newSecondaryText =
                    period === 'month'
                        ? `${
                              quotaResponse?.data?.periodQuotas?.find(
                                  periodObj => periodObj.period === 'month'
                              )?.quota
                          }`
                        : `${quotaResponse?.data?.totalQuota}`;

                const newTotalQuotaValue =
                    period === 'month'
                        ? quotaResponse?.data?.periodQuotas?.find(
                              periodObj => periodObj.period === 'month'
                          )?.quota
                        : quotaResponse?.data?.totalQuota;

                setSalesCard(currentSalesCardData => ({
                    ...currentSalesCardData,
                    secondaryText: formatPrice(newSecondaryText),
                    totalQuotaValue: newTotalQuotaValue,
                }));
            };

            const getOmniIndicators = async () => {
                setOmniIndicators(currState => ({
                    ...currState,
                    loaded: false,
                }));

                setTotalValue(currState => ({
                    ...currState,
                    loaded: false,
                    value: null,
                }));

                try {
                    const omniIndicatorsResponse = await omniApi.get(
                        `/storeData/maisVendas/consolided?startDate=${initialDate.format(
                            'YYYY-MM-DD'
                        )}&endDate=${finalDate.format(
                            'YYYY-MM-DD'
                        )}&filial=${userProfile.nomeFilial ||
                            userProfile.nomeFilialExtra}`,
                        {
                            headers: {
                                Authorization: `Ra0YaHTblw7MkUbcCpSKklfCA15ew74S`,
                            },
                        }
                    );

                    const newOmniIndicators =
                        omniIndicatorsResponse.data[0] ||
                        omniIndicatorsResponse.data ||
                        null;

                    if (newOmniIndicators) {
                        setOmniIndicators(currentOmniCardData => ({
                            ...currentOmniCardData,
                            mainText: {
                                omniTMA:
                                    isNaN(newOmniIndicators?.tma_horas) ||
                                    (!newOmniIndicators?.tma_horas &&
                                        !newOmniIndicators?.receitas)
                                        ? '-h'
                                        : formatNumberToHour(
                                              newOmniIndicators?.tma_horas
                                          ),
                                omniTC:
                                    !newOmniIndicators?.taxa_cancelamento &&
                                    !newOmniIndicators?.receitas
                                        ? '-%'
                                        : `${newOmniIndicators?.taxa_cancelamento}%`,
                            },
                            secondaryText: formatPrice(
                                `${newOmniIndicators?.receitas}`
                            ),
                            value: newOmniIndicators?.receitas,
                            loaded: true,
                        }));
                    }
                } catch (err) {
                    if (err.response.status !== 404) {
                        Snackbar.error('Erro retornando a indicadores Omni');
                        return;
                    }
                    setOmniIndicators(currentOmniCardData => ({
                        ...currentOmniCardData,
                        mainText: {
                            omniTMA: '-h',
                            omniTC: '-%',
                        },
                        secondaryText: 'R$ 0,00',
                        value: 0,
                        loaded: true,
                    }));
                }
            };

            getStoreRanking();
            getAggregateSales();
            getQuota();
            getOmniIndicators();
        }
    }, [checkIfCanMakeRequests, finalDate, userProfile, initialDate, period]);

    useEffect(() => {
        setTotalValue(currState => ({
            ...currState,
            loaded: false,
            value: null,
        }));

        if (
            typeof salesCard?.value !== 'undefined' &&
            salesCard?.loaded &&
            typeof omniIndicators?.value !== undefined &&
            omniIndicators?.loaded
        ) {
            setTotalValue({
                loaded: true,
                value: formatPrice(salesCard?.value + omniIndicators?.value),
            });
        }
    }, [salesCard, omniIndicators]);

    useEffect(() => {
        setSalesPercentage(currState => ({
            ...currState,
            loaded: false,
        }));

        setOmniPercentage(currState => ({
            ...currState,
            loaded: false,
        }));

        if (salesCard && omniIndicators) {
            const totalSoldValue = salesCard.value + omniIndicators.value;

            const salesPercentageOfSold = Math.round(
                (salesCard.value / totalSoldValue) * 100
            );

            const salesPercentageOfQuota = Math.round(
                (salesCard.value / salesCard.totalQuotaValue) * 100
            );

            const omniPercentageOfQuota = Math.round(
                (omniIndicators.value / salesCard.totalQuotaValue) * 100
            );

            const soldPercentageOfQuota = Math.round(
                (totalSoldValue / salesCard.totalQuotaValue) * 100
            );

            setSalesPercentage(currentSalesData => ({
                ...currentSalesData,
                percentageOfSold: salesPercentageOfSold,
                percentageOfQuota: salesPercentageOfQuota,
                totalPercentageOfQuota: soldPercentageOfQuota,
                loaded: true,
            }));

            setOmniPercentage(currentOmniData => ({
                ...currentOmniData,
                percentageOfQuota: omniPercentageOfQuota,
                loaded: true,
            }));
        }
    }, [salesCard, omniIndicators, totalValue]);

    return (
        <>
            <Header />
            <DateRangePicker hidden={!salesCard?.loaded} assessment />
            <PageLayout
                storeRanking={storeRanking}
                omniIndicators={omniIndicators}
                salesCard={salesCard}
                totalValue={totalValue}
                salesPercentage={salesPercentage}
                omniPercentage={omniPercentage}
                userProfile={userProfile}
            />
        </>
    );
}
